import { gzip, ungzip } from "pako";
import { diff_match_patch, DIFF_DELETE, DIFF_INSERT, DIFF_EQUAL } from "diff-match-patch";
export const functions = {
    zip: (value) => {
        const encoder = new TextEncoder();
        return gzip(encoder.encode(value));
    },
    unzip: (data) => {
        const bytes = ungzip(data), decoder = new TextDecoder(), result = decoder.decode(bytes);
        return result;
    },
    diff: (text1, text2) => {
        return text1 === text2 ? null : new diff_match_patch()
            .diff_main(text1, text2)
            .map(diff => {
            switch (diff[0]) {
                case DIFF_DELETE:
                    return -diff[1].length;
                case DIFF_EQUAL:
                    return diff[1].length;
                case DIFF_INSERT:
                    return diff[1];
                default:
                    return 0;
            }
        });
    }
};
