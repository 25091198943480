import { functions } from "worker/functions";
const functionList = ["zip", "unzip", "diff"], send = postMessage;
onmessage = function (message) {
    const { id, fn, params } = message.data;
    try {
        if (functionList.includes(fn)) {
            send({
                id,
                type: "result",
                result: functions[fn].apply(null, params),
            });
        }
        else {
            send({ id, type: "error" });
            console.error(`Invalid function name '${fn}'`);
        }
    }
    catch (e) {
        send({ id, type: "error" });
        console.error(e);
    }
};
